import {get, post, put} from "@/api/axios";

// 用户管理-列表
export const list = params => get(`/web/user/manage/list`,params);

// 用户管理-状态修改
export const update = params => put(`/web/user/manage/state/update`,params);

export const count = params => get('/web/user/manage/count',params)

export const honeyUpdate = params => put('/web/user/manage/honey/update',params)

export const honeyUpdateGetCode = params => get('/web/user/manage/honey/update/get/code',params)

export const honeyUpdateCodeValidate = params => post('/web/user/manage/honey/update/code/validate',params)