<template>
  <div>
    <el-dialog
        title="安全验证"
        :visible.sync="newShow"
        width="35%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="handleSubmit">
        <template slot="phone">
          <div>138****8688</div>
        </template>
        <template slot="code">
          <div class="flex">
            <el-input placeholder="请输入6位校验码" v-model="form.code" />
            <el-button type="primary" :disabled="codText !== '点击获取验证码'" @click="handleCode">{{ codText }}</el-button>
          </div>
        </template>
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
    <el-dialog
        title="配置蜜豆"
        :visible.sync="showPoints"
        width="35%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="formPoints"
                 :option="formOptionPoints"
                 @submit="handleSubmitPoints">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {honeyUpdate, honeyUpdateGetCode, honeyUpdateCodeValidate} from "@/api/user/manage";

export default {
  name: "index",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: {
        id: '',
        max: 0,
        type: 1,
      }
    }
  },
  data(){
    return{
      newShow: this.show,
      loading: false,
      codText: '点击获取验证码',
      form: {},
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '手机号码',
            prop: 'phone',
            span: 24
          },
          {
            label: '校验码',
            prop: 'code',
            span: 24,
            rules: [
              { required: true, message: '请输入校验码', trigger: 'blur' }
            ]
          }
        ]
      },
      showPoints: false,
      formPoints: this.info,
      formOptionPoints: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '配置类型',
            prop: 'type',
            type: 'radio',
            dicData: [
              {
                label: '增加',
                value: 1
              },
              {
                label: '减少',
                value: 2
              }
            ],
            span: 24,
            row: true,
            rules: [
              { required: true, message: '请选择配置类型', trigger: 'blur' }
            ],
            change: (val) => {
              this.$refs.form.clearValidate('honey');
            }
          },
          {
            label: '蜜豆数量',
            prop: 'honey',
            type: 'number',
            minRows: 1,
            maxRows: 999999,
            precision: 0,
            controls: false,
            span: 24,
            row: true,
            rules: [
              { required: true, message: '请输入蜜豆数量', trigger: 'blur' },
              // form.honey 不能大于 form.max
              { validator: (rule, value, callback) => {
                  if (this.form.type === 2 && value > this.form.max) {
                    callback(new Error('当前蜜豆数为' + this.form.max))
                  } else {
                    callback()
                  }
                }, trigger: 'blur' }
            ]
          }
        ]
      }
    }
  },
  methods: {
    handleCode(){
      honeyUpdateGetCode().then(res=>{
        if(res.code === 200){
          this.$message.success('获取成功');
          let time = 60;
          this.codText = time + 's后重新获取';
          let timer = setInterval(()=>{
            time--;
            this.codText = time + 's后重新获取';
            if(time === 0){
              clearInterval(timer);
              this.codText = '点击获取验证码';
            }
          },1000)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    handleSubmit(form,done){
      honeyUpdateCodeValidate({
        code: form.code
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('验证成功');
          this.newShow = false;
          this.showPoints = true;
        }else{
          this.$message.error(res.msg)
          done();
        }
      }).catch(()=>{
        done();
      })
    },
    // 提交
    handleSubmitPoints(form,done){
      // console.log('提交',form)
      honeyUpdate(form).then(res=>{
        if(res.code === 200){
          this.$message.success('操作成功');
          this.$emit('close',true);
        }else{
          this.$message.error(res.msg)
          done();
        }
      }).catch(()=>{
        done();
      })
    },
    handleClose(){
      this.$emit('close',false);
    }
  }
}
</script>

<style scoped>

</style>