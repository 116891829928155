<template>
  <div class="main-content">
    <avue-crud
        class="aVueCrud"
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items flex-wrap">
          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">注册时间: </span>
            <div class="width300">
              <el-date-picker
                  v-model="date"
                  type="daterange"
                  range-separator="~"
                  @change="handleDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.state" clearable>
              <el-option label="状态" value=""></el-option>
              <el-option label="正常" :value="1"></el-option>
              <el-option label="已禁用" :value="2"></el-option>
              <el-option label="已注销" :value="3"></el-option>
            </el-select>
          </div>
          <el-input size="medium" v-model="searchData.name" placeholder="用户姓名/手机号/id" :maxlength="100" class="marginRight10 width300 marginBottom10"/>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" style="background: #c280ff;color: #ffffff;border-color: #c280ff" icon="el-icon-download" @click="handleExport">导出</el-button>
          </div>
        </div>
      </template>

      <template slot="avatarUrl" slot-scope="{ row }">
        <div class="flex justify-content-center">
          <el-image :src="row.avatarUrl" fit="contain" style="width: 40px; height: 40px"/>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button size="mini" type="text" v-if="row.state === 1" icon="el-icon-turn-off" @click="handleOperation(row,2)">停用</el-button>
        <el-button size="mini" type="text" v-if="row.state === 2" icon="el-icon-open" @click="handleOperation(row,1)">开启</el-button>
        <el-button size="mini" type="text" v-if="row.state !== 3 && userInfo.id === '618d882454d3b5f1e0d7373c78ac31ac'" icon="el-icon-setting" @click="handleConfig(row)">配置蜜豆</el-button>
      </template>

      <template #footer>
        <div class="aVueCrudNum">
          <span class="marginRight20">汇总统计：可用蜜豆积分：{{ total.useHoney }}</span>
          <span>冻结蜜豆积分：{{ total.frozenHoney }}</span>
        </div>
      </template>
    </avue-crud>

    <points v-if="show" :show="show" :info="info" @close="handlePointsClose"/>
  </div>
</template>

<script>
import { list, update, count, honeyUpdate } from "@/api/user/manage";
import qs from "qs";
import points from "@/components/points/index.vue";
import {mapGetters} from "vuex";
export default {
  name: "index",
  components: {
    points
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  data(){
    return{
      total: {
        useHoney: 0,
        frozenHoney: 0
      },
      date: [],
      searchData: {
        createTimeStart: '',
        createTimeEnd: '',
        name: '',
        state: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option:{
        title:'用户管理',
        titleSize:'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: '序号',
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '用户昵称',
            prop: 'nickname'
          },
          {
            label: '用户头像',
            prop: 'avatarUrl'
          },
          {
            label: '用户手机号',
            prop: 'phone'
          },
          {
            label: '用户id',
            prop: 'code'
          },
          {
            label: '用户姓名',
            prop: 'username'
          },
          {
            label: '可用蜜豆积分',
            prop: 'useHoney'
          },
          {
            label: '冻结蜜豆积分',
            prop: 'frozenHoney'
          },
          {
            label: '是否推荐朋友',
            prop: 'friendRecommend',
            type: 'radio',
            dicData: [
              {
                label: '是',
                value: 1
              },
              {
                label: '否',
                value: 2
              }
            ]
          },
          {
            label: '用户角色',
            prop: 'agentType',
            type: 'radio',
            dicData: [
              {
                label: '认证经纪人',
                value: 1
              },
              {
                label: '全民经纪人',
                value: 2
              }
            ]
          },
          {
            label: '注册时间',
            prop: 'createTime'
          },
          {
            label: '状态',
            prop: 'state',
            type: 'radio',
            dicData: [
              {
                label: '正常',
                value: 1
              },
              {
                label: '已禁用',
                value: 2
              },
              {
                label: '已注销',
                value: 3
              }
            ]
          }
        ]
      },

      show: false,
      info: {
        id: '',
        max: 0,
        type: 1,
      }
    }
  },
  mounted() {
    this.getCount()
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    async getCount(){
      const { data } = await count({ ...this.searchData })
      console.log(data)
      this.total = data;
    },
    // 选择时间
    handleDate(val){
      console.log(val)
      if(val.length > 0){
        this.searchData.createTimeStart = val[0] + ' 00:00:00';
        this.searchData.createTimeEnd = val[1] + ' 23:59:59'
      }else{
        this.searchData.createTimeStart = '';
        this.searchData.createTimeEnd = '';
      }
    },
    // 查询
    handleSearch(){
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
      this.getCount();
    },
    // 重置
    handleReset(){
      // console.log('重置')
      this.searchData = {
        createTimeStart: '',
        createTimeEnd: '',
        name: '',
        state: ''
      }
      this.date = [];
      this.page.currentPage = 1;
      this.onLoad();
      this.getCount();
    },
    // 开启/停用
    handleOperation(row,state){
      // console.log('开启',row)
      this.$confirm(state === 1 ? '开启后用户可正常访问小程序及APP，确认开启？' : '停用后用户将无法访问小程序及APP，确认停用该账号？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          // console.log(action)
          done();
          if(action === 'confirm'){
            update({
              id: row.id,
              state: state
            }).then(res=>{
              if(res.code === 200){
                this.$message.success('操作成功');
                this.onLoad();
              }else{
                this.$message.error(res.msg)
              }
            })
          }
        }
      });
    },
    // 配置蜜豆
    handleConfig(row){
      // console.log('配置蜜豆',row)
      this.show = true;
      this.info = {
        id: row.id,
        max: row.useHoney,
        type: 1,
      }
    },
    handlePointsClose(e){
      this.show = false;
      this.info = {
        id: '',
        max: 0,
        type: 1
      }
      e && this.onLoad();
    },
    // 导出
    handleExport(){
      window.open(`/api/web/user/manage/export?${qs.stringify(this.searchData)}`,'_self');
    }
  }
}
</script>

<style scoped>
.aVueCrud{
  position: relative;
}
.aVueCrudNum{
  /*position: absolute;*/
  /*left: 20px;*/
  /*bottom: 40px;*/
  font-size: 13px;
  font-weight: 400;
  color: #606266;
  line-height: 28px;
}
</style>